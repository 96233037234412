// SCANTHNG

EVT.use(EVT.Scan);
EVT.use(EVT.WS);
$('.spinnerLoader').remove()
// let dialogue =  (new URLSearchParams(window.location.search)).get('dialogue');
if ((new URLSearchParams(window.location.search)).get('thng')) {
     $('#scan-button').html("Take a picture of the product");

    // localStorage.setItem('dialogue', '1');
}
// if (!dialogue && (!localStorage.getItem('dialogue'))) {
//     $('#scan-button').html("Please scan QR code");
//
//     localStorage.setItem('dialogue', '1');
// } else if (+localStorage.getItem('dialogue')===0) {
//     $('#scan-button').html("Please scan QR code");
//     localStorage.setItem('dialogue', '1');
// } else if (!localStorage.getItem('dialogue')===1) {
//     $('#scan-button').html("Please proceed to the next step to authenticate it");
//     localStorage.setItem('dialogue', '2');
// }

function scanQRCode() {
    // Clear any previous error message
    $('.error-msg').html('');
    $('.loader').addClass('on');


    app
    .scanStream({
            filter: { method: '2d', type: 'qr_code' },
            containerId: 'stream_container'
        })
        .then(res => {
            for (let i = 0; i < res.length; i++){
                let thngId = (new URLSearchParams(window.location.search)).get('thng');
                if (res[i].meta.method==='2d' && !thngId) {
                    console.log(res);
                    window.location.replace(res[i].meta.value);
                    return ;
                }
            }
            let user = new EVT.User(res[0].user.apiKey);
            $('.loader').parent().append('<div>We are verifying your product. This may take a while...</div><br>');
            let identifiedProductId = null;
            let score = null;
            localStorage.setItem('scan_result', JSON.stringify(res[0]));

            // console.log(JSON.stringify(res));
            if (res[0].meta.score === 0) {
                identifiedProductId = '';
                score = 0;
                // is a valid QR code but we dont have it in our sustem
                console.log('Invalid code sent to Evrythng :' + res[0].meta.value);

                invalidCode(res[0].meta);
            } else {

                let thngId = (new URLSearchParams(window.location.search)).get('thng');
                // getParameterByName('thngId') will not work at this step, it will silently fail and return null
                // let thngId = getParameterByName('thngId');
                let appKey = getParameterByName('appKey');
                console.log(JSON.stringify(res[0]));

                identifiedProductId = res[0].results[0].product.id;
                score = res[0].meta.score / 100;
                var correctThng = null;
                return user.thng(thngId).read().then(thngs => {
                    correctThng = thngs;
                    console.log(JSON.stringify(correctThng));
                    user.action('_product_auth').create({
                        thng: thngId,
                        identifiers: {authType: 'product_fingerprint'},
                        customFields: {
                            probability: score * +(correctThng.product === identifiedProductId),
                            meta: res[0].meta,
                            results: res[0].results
                        }
                    }).then(data => {
                        return app.product(correctThng.product).read()
                    }).then(product => {
                        localStorage.setItem('product', JSON.stringify(product));
                        return []
                    }).then(() => {
                        console.log('action sent ');
                        // window.thng = thngs[0];
                        // $('.loader').append('We are verifying your product. This may take a while')
                        let authenticInterval = setInterval(() => {
                            user.thng(correctThng.id).read().then(thng => {
                                if (thng.properties['authentic'] !== undefined) {
                                    localStorage.setItem('thng', JSON.stringify(thng));
                                    if (thng.properties['authentic']===true) {
                                        window.location.replace('http://verify.evrythng.io/verified.html')
                                    } else {
                                        window.location.replace('http://verify.evrythng.io/unverified.html')
                                    }
                                    $('.loader').removeClass('on');
                                    clearInterval(authenticInterval);
                                }
                            }).catch(err => {
                                console.error(err);
                                clearInterval(authenticInterval)
                            });

                        }, 50);

                    })
                })
            }
        }).catch(error => {
        // Report an error

        console.error(error);

        $('.error-msg').html(
            'Sorry - I can\'t recognise this picture, please take another one.'
        );
    })
        .then(() => {
            // $('.loader').removeClass('on');
        });
}

function scanProduct() {
    // Clear any previous error message
    $('.error-msg').html('');
    $('.loader').addClass('on');
    $('#spinnerParent').append("<div class=\"spinnerLoader\"></div>");
  app
        .scan({
            filter: 'method=ir&type=image',
            createAnonymousUser: true,
            imageConversion : {
  greyscale: false,
  resizeTo: 500,
  exportQuality: 1.0
}
        })
        .then(res => {
            for (let i = 0; i < res.length; i++){
                let thngId = (new URLSearchParams(window.location.search)).get('thng');
                if (res[i].meta.method==='2d' && !thngId) {
                    console.log(res);
                    window.location.replace(res[i].meta.value);
                    return ;
                }
            }
            let user = new EVT.User(res[0].user.apiKey);
            $('.loader').parent().append('<div>We are verifying your product. This may take a while...</div><br>');
            let identifiedProductId = null;
            let score = null;
            localStorage.setItem('scan_result', JSON.stringify(res[0]));

            // console.log(JSON.stringify(res));
            if (res[0].meta.score === 0) {
                identifiedProductId = '';
                score = 0;
                // is a valid QR code but we dont have it in our sustem
                console.log('Invalid code sent to Evrythng :' + res[0].meta.value);

                invalidCode(res[0].meta);
            } else {

                let thngId = (new URLSearchParams(window.location.search)).get('thng');
                // getParameterByName('thngId') will not work at this step, it will silently fail and return null
                // let thngId = getParameterByName('thngId');
                let appKey = getParameterByName('appKey');
                console.log(JSON.stringify(res[0]));

                identifiedProductId = res[0].results[0].product.id;
                score = res[0].meta.score / 100;
                var correctThng = null;
                return user.thng(thngId).read().then(thngs => {
                    correctThng = thngs;
                    console.log(JSON.stringify(correctThng));
                    user.action('_product_auth').create({
                        thng: thngId,
                        identifiers: {authType: 'product_fingerprint'},
                        customFields: {
                            probability: score * +(correctThng.product === identifiedProductId),
                            meta: res[0].meta,
                            results: res[0].results
                        }
                    }).then(data => {
                        return app.product(correctThng.product).read()
                    }).then(product => {
                        localStorage.setItem('product', JSON.stringify(product));
                        return []
                    }).then(() => {
                        console.log('action sent ');
                        // window.thng = thngs[0];
                        // $('.loader').append('We are verifying your product. This may take a while')
                        let authenticInterval = setInterval(() => {
                            user.thng(correctThng.id).read().then(thng => {
                                if (thng.properties['authentic'] !== undefined) {
                                    clearInterval(authenticInterval);
                                    localStorage.setItem('thng', JSON.stringify(thng));
                                    if (thng.properties['authentic']===true) {
                                            window.location.replace('http://verify.evrythng.io/verified.html');
                                    } else {
                                            window.location.replace('http://verify.evrythng.io/unverified.html');
                                    }
                                }
                            }).catch(err => {
                                console.error(err);
                                clearInterval(authenticInterval);

                            }).then(data=>{
                                      $('.loader').removeClass('on');

                            });

                        }, 50);

                    })
                })
            }
        }).catch(error => {
        // Report an error
            alert('I was unable to recognise your product. Please take another picture.');
            $('.spinnerLoader').remove();

        // console.error(error);
        //
        // $('.error-msg').html(
        //     'Sorry - I can\'t recognise this picture, please take another one.'
        // );
    })
        .then(() => {
            // $('.loader').removeClass('on');
        });
}
